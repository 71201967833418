<template>
  <div>
    <Navbar />
    <div>
      <div class="mb-10">
        <VideoBackground
          src="/IMG_0302.TRIM.mp4"
          poster="poster.jpg"
          :sources="[
            { src: '900>.mp4', res: 900, autoplay: true },
            {
              src: '640.mp4',
              res: 638,
              autoplay: true,
            },
          ]"
          class="max-h-[50%] h-[40vh] md:h-[85vh]"
          overlay="linear-gradient(95deg,#2a4ae430,#fb949e6b)"
        >
          <div
            class="flex flex-col text-center items-center justify-center space-y-10 h-[40vh] md:h-[85vh]"
          >
            <p
              class="font-poppins-semibold font-black text-xl md:text-8xl text-white transition-all duration-500 ease-in-out"
            >
              OVER A DECADE, ONE GOAL
            </p>
            <p
              class="text-md md:text-2xl text-white transition-all duration-500 ease-in-out"
            >
              Choose for yourself something new
            </p>
            <div class="flex justify-center">
              <router-link
                to="/contact-us"
                class="custom_btn_small_device md:custom_btn"
              >
                Enquire Now
              </router-link>
            </div>
          </div>
        </VideoBackground>
      </div>

      <!-- About Us -->
      <div>
        <div class="space-y-3 text-center uppercase mb-10">
          <h2 class="text-3xl font-bold">about company</h2>
        </div>
        <!-- <div
          class="container mx-auto flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-12"
        > -->
        <div class="container mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div class="">
              <div class="text-xs md:text-base space-y-2">
                <p>
                  Lifestyle Technologies is a quickly evolving custom AV and
                  technology integration company.
                </p>

                <p>
                  Formed in 2013, we have built our clientele and credibility by
                  focusing on one key goal: delivering on our clients’ requests
                  with attention to detail. No matter how big or small the job
                  is, client satisfaction is the most important thing to us.
                </p>

                <p>
                  Our expertise encompasses everything from audio visual
                  systems, lighting design and automation, data and IT
                  networking, graphic design, security and surveillance, through
                  to acoustic treatments and soundproofing construction with
                  core focus on the residential and hospitality industries. Each
                  project is custom-made; all our work is customized to the
                  needs and desires of the individual client.
                </p>

                <p>
                  All projects are treated with the utmost care and
                  consideration. They’re managed step by step by our project and
                  onsite managers. From initial concept drawings drafted by our
                  in-house designers, to complex requirements carefully planned
                  by our systems professionals through to custom installation
                  and automation; our in-house team at Lifestyle Technologies
                  Limited will go out of our way to provide for our clients.
                </p>

                <p class="">
                  Our cutting-edge systems are backed by ongoing support from
                  our IT and AV team. Custom support plans are available for
                  both commercial and residential clients.
                </p>
              </div>
              <div class="mt-10">
                <router-link
                  to="/contact-us"
                  class="custom_btn_small_device md:custom_btn"
                  >Enquire Now</router-link
                >
              </div>
            </div>
            <div class="">
              <img
                src="../assets/images/images/Control4-system-1.jpeg"
                class="w-full h-full rounded-xl"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Services -->
      <div class="md:my-10 my-40 py-10">
        <div class="container mx-auto">
          <div class="space-y-3 text-center mb-10">
            <h2 class="text-3xl font-bold uppercase">Services</h2>
            <h3 class="text-KellyGreen uppercase">we do it</h3>
            <p class="text-xs md:text-base mx-auto max-w-lg">
              With over 10 years experience , we have amassed a wealth of
              knowledge and experience that we apply to all products and it’s no
              wonder we are consistent and the best at what we offer our
              clients.
            </p>
          </div>
          <div
            class="container mx-auto grid md:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-20 h-[1000px] md:h-64"
          >
            <router-link to="/residential" class="home_cinema_bg mx-8 md:mx-0">
              <p
                class="absolute bottom-8 text-white font-bold text-ld md:text-2xl font-poppins-semibold"
              >
                Residential
              </p>
            </router-link>
            <router-link
              to="/hospitality"
              class="tv_and_gaming_bg mx-8 md:mx-0"
            >
              <p
                class="absolute bottom-8 text-white font-bold text-ld md:text-2xl font-poppins-semibold"
              >
                Hospitality
              </p>
            </router-link>
            <router-link
              to="/commercial-and-corporate-and-corporate"
              class="music_bg mx-8 md:mx-0"
            >
              <p
                class="absolute bottom-8 text-white font-bold text-ld md:text-2xl font-poppins-semibold"
              >
                commercial-and-corporate &amp; Corporate
              </p>
            </router-link>
            <router-link to="/consulting" class="smart_home_bg mx-8 md:mx-0">
              <p
                class="absolute bottom-8 text-white font-bold text-ld md:text-2xl font-poppins-semibold"
              >
                Consulting
              </p>
            </router-link>
          </div>
        </div>
      </div>

      <!-- News -->
      <div class="container mx-auto">
        <div class="relative transition duration-1000 ease-in-out">
          <img
            src="../assets/images/istockphoto-1140016975-612x612.jpeg"
            class="rounded-xl w-full h-full md:h-[500px] object-cover"
            id="brightness"
            alt=""
          />
          <div
            class="bg-black bg-opacity-40 backdrop-filter backdrop-blur-md rounded-xl border border-KellyGreen space-y-2 md:space-y-8 absolute bottom-4 p-8 mx-2 md:mx-20 text-white font-bold text-2xl font-poppins-semibold"
          >
            <p class="text-2xl md:text-4xl font-poppins-semibold">News</p>
            <p class="text-KellyGreen">Latest Event</p>
            <p class="text-sm md:text-lg max-w-md">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
              voluptas unde libero quaerat delectus aperiam facere, molestiae,
              reiciendis ipsam, officiis labore? Ab cupiditate vitae repellendus
              error minima, voluptatem illum totam?
            </p>
            <br />
            <span class="custom_btn_small_device md:custom_btn"
              >14 September</span
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import VideoBackground from "vue-responsive-video-background-player";

export default {
  name: "HomeView",
  components: {
    Navbar,
    Footer,
    VideoBackground,
  },
  setup() {
    const activeMain = () => {
      return $route.path === "/";
    };
    return {
      activeMain,
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 600px;
}

#brightness {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
</style>
