<template>
  <div>
    <div class="bg-black">
      <Navbar />
      <div class="become-a-partner-bg space-y-4">
        <p class="text-center text-5xl md:text-7xl text-white">
          Become A Partner...
        </p>
        <div class="flex justify-center">
          <EnquiryNow />
        </div>
      </div>
    </div>
    <div class="my-10 md:my-20">
      <div class="container mx-auto space-y-6 mb-10 md:mb-24">
        <p class="text-2xl md:text-5xl">Our Partners</p>
        <!-- <p class="max-w-xl text-xl font-thin">
          We currently work with industry experts in various fields such as
          architecture, construction, interior and lighting design. Get in touch
          to collaborate with us on your next project.
        </p> -->
      </div>
      <div
        class="bg-white flex flex-row w-full sm:w-full overflow-auto scrollbar-hide space-x-4 md:space-x-10 relative"
      >
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/salto-logo.svg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Control-4-Logo.jpeg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/vda-logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Lyngdorf-Logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/mk-some.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/salto-logo.svg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Control-4-Logo.jpeg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/vda-logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Lyngdorf-Logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/mk-some.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/salto-logo.svg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Control-4-Logo.jpeg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/vda-logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Lyngdorf-Logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/mk-some.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/salto-logo.svg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Control-4-Logo.jpeg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/vda-logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Lyngdorf-Logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/mk-some.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/salto-logo.svg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Control-4-Logo.jpeg"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/vda-logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/Lyngdorf-Logo.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
        <div class="slide flex flex-row items-center justify-center space-x-5">
          <span class="">
            <img
              src="../assets/images/mk-some.png"
              class="w-10 md:w-24 xl:w-40 py-4 md:py-6"
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import EnquiryNow from "@/components/EnquiryNow.vue";
export default {
  name: "PartnerShipView",
  components: {
    Navbar,
    Footer,
    EnquiryNow,
  },
};
</script>

<style scoped>
.slide {
  border-radius: 50%;
  animation: scroll 50s linear infinite;
}
.slide span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem !important;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}

@media screen and (max-width: 496px) {
  .slide span {
    width: 5rem !important;
    /* height: 5rem !important; */
  }
}
</style>
