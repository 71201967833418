<template>
  <div class="py-10">
    <div class="container mx-auto">
      <div class="flex flex-col md:items-center space-y-3 mb-5">
        <h2 class="text-xl md:text-3xl font-bold">
          Sign up for our company news
        </h2>
        <h3 class="text-sm md:text-base text-KellyGreen">
          You will receive notifications about company news
        </h3>
        <div class="group w-72 md:w-80 lg:w-96">
          <div class="relative flex items-center">
            <input
              id="10"
              type="text"
              class="text-black peer relative h-10 w-full rounded-md bg-gray-50 pl-4 pr-20 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"
            />
            <button
              class="absolute right-2 h-7 w-16 rounded-md bg-KellyGreen text-xs font-semibold text-black transition-all duration-200 ease-in-out group-focus-within:bg-yellow-500 group-focus-within:hover:bg-transparent group-focus-within:hover:border group-focus-within:hover:border-KellyGreen"
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-6/12">
          <div class="my-6">
            <img src="../assets/images/logo.png" class="w-28 md:w-40" alt="" />
          </div>
          <!-- <p class="text-sm md:text-base max-w-xs">
            We let you you get your items in easy steps. You no longer have to
            go through the stress to get your goods at your door step.
          </p> -->
        </div>
        <div class="w-1/2 md:w-3/12 flex flex-col space-y-2">
          <p class="py-6 text-base md:text-xl">Social Media</p>
          <a
            href="https://www.facebook.com/profile.php?id=100070316991173&mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
            class="text-xs md:text-base underline hover:text-KellyGreen"
            >Facebook</a
          >
          <a
            href="https://twitter.com/_lifestyletech?t=1niKlh5ieL0UlDpSU7yv9g&s=09"
            target="_blank"
            rel="noopener noreferrer"
            class="text-xs md:text-base underline hover:text-KellyGreen"
            >Twitter</a
          >
          <a
            href="https://instagram.com/_lifestyletech?igshid=ZDc4ODBmNjlmNQ=="
            target="_blank"
            rel="noopener noreferrer"
            class="text-xs md:text-base underline hover:text-KellyGreen"
            >Instagram</a
          >
          <a
            href="https://www.linkedin.com/company/lifestyle-technologies-home-automation-company-in-nigeria/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-xs md:text-base underline hover:text-KellyGreen"
            >LinkedIn</a
          >
        </div>
        <div class="w-1/2 md:w-3/12 flex flex-col space-y-2">
          <p class="py-6 text-base md:text-xl hover:text-KellyGreen">Contact</p>
          <span to="/" class="text-xs md:text-base"
            ><span class="text-KellyGreen text-base md:text-base"
              >Address:</span
            >
            16, Adebisi Oguniyi Crescent, off Oladimeji Alo Street, Lekki Phase
            1.</span
          >
          <span to="/" class="text-xs md:text-base"
            ><span class="text-KellyGreen text-base md:text-base">Number:</span>
            +234 (0) 8037492095</span
          >
          <span to="/" class="text-xs md:text-base">
            <span class="text-KellyGreen text-base md:text-base">Email:</span
            >info@lifestylegroupnigeria.com</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "Footer",
});
</script>

//
