<template>
  <div>
    <div class="take-a-tour-bg">
      <Navbar />
      <div
        class="container mx-auto flex flex-col justify-center space-y-4 text-white"
      >
        <h1
          class="flex flex-col space-y-4 md:space-y-8 font-bold text-2xl md:text-5xl mt-24"
        >
          <span> The largest guest</span>
          <span> technology provider in </span>
          <span>hospitality in West Africa</span>
        </h1>
        <!-- <p class="text-center text-lg md:text-xl">
          See how you can get your Smart products.
        </p> -->
      </div>
    </div>
    <div class="container mx-auto space-y-10 my-10">
      <!--  Automated RTI -->
      <div
        class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
      >
        <div class="w-full md:w-1/2 mb-4 flex justify-center">
          <img
            src="../assets/images/images/Home-and-Commercial-Automation-Accessories-RTI.png"
            class="w-full h-44 md:h-full object-cover"
            alt=""
          />
        </div>
        <div class="w-full md:w-1/2 text-left space-y-3">
          <h2
            class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
          >
            Home Page
          </h2>

          <p class="text-left max-w-md text-xs md:text-base">
            Our home page serves as a gateway to our world of possibilities.
            Discover a sleek and intuitive design that showcases our commitment
            to aesthetics and functionality. Get a glimpse of the latest
            products, captivating visuals, and a user-friendly interface that
            sets the tone for your journey.
          </p>
        </div>
      </div>

      <!-- Salto -->
      <div
        class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
      >
        <div class="w-full md:w-1/2 text-left">
          <h2
            class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
          >
            Products and Services
          </h2>

          <p class="text-left max-w-md text-xs md:text-base">
            Dive deeper into our range of exceptional products and services.
            Explore the extensive catalog that encompasses technological marvels
            designed to enhance your lifestyle. From smart home solutions to
            security systems, we offer a diverse array of offerings tailored to
            meet your unique needs.
          </p>
        </div>
        <div class="w-full md:w-1/2 mb-4 flex justify-center">
          <img
            src="../assets/images/smart-22.jpeg"
            class="rounded-lg w-full h-44 md:h-full object-cover"
            alt=""
          />
        </div>
      </div>

      <!-- MK-sound -->

      <!-- another -->
      <div
        class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
      >
        <div class="w-full md:w-1/2 mb-4 flex justify-center">
          <img
            src="../assets/images/smart-6.jpeg"
            class="rounded-lg w-full h-44 md:h-full object-cover"
            alt=""
          />
        </div>
        <div class="w-full md:w-1/2 text-left">
          <h2
            class="text-center md:text-left text-lg md:text-2xl mb-4 md:mb-6 md:max-w-sm"
          >
            About Us
          </h2>

          <p class="text-left max-w-md text-xs md:text-base">
            Learn more about our vision, mission, and the values that drive us
            forward. Discover the story behind Lifestyle Technologies Limited
            and gain insights into our dedication to innovation, quality, and
            customer satisfaction. Get to know our passionate team and the
            expertise they bring to the table.
          </p>
        </div>
      </div>

      <!-- another -->

      <div
        class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
      >
        <div class="w-full md:w-1/2 text-left">
          <h2
            class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
          >
            Testimonials
          </h2>

          <p class="text-left max-w-md text-xs md:text-base">
            Delve into the experiences of our satisfied customers. Our
            testimonial section is a treasure trove of authentic feedback and
            success stories. Hear firsthand accounts of how our products and
            services have transformed lives and exceeded expectations.
          </p>
        </div>
        <div class="w-full md:w-1/2 mb-4 flex justify-center">
          <img
            src="../assets/images/smart-22.jpeg"
            class="rounded-lg w-full h-44 md:h-full object-cover"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="flex flex-col md:flex-row justify-center items-center">
        <div class="w-full md:w-8/12 flex justify-center">
          <img
            src="../assets/images/smart-home.png"
            class="md:rounded-l-lg w-full h-44 md:h-96 object-cover"
            alt=""
          />
        </div>

        <div
          class="w-full md:w-4/12 text-left bg-KellyGreen h-fit md:h-96 flex flex-col justify-center items-center p-10"
        >
          <h2
            class="text-center md:text-left text-xl md:text-xl mb-4 md:mb-6 md:max-w-sm"
          >
            STREAMLINE CHECKOUTS
          </h2>

          <p class="text-xs md:text-base">
            Lifestyle interactive system lets Guest review their accounts,
            purchase late checkout, and get all receipt without calling the
            front desk or standing in line.
          </p>
        </div>
      </div> -->

      <!-- netflix -->
      <div class="interact-bg">
        <div
          class="container mx-auto flex flex-col justify-center items-center space-y-4 text-white"
        >
          <h1
            class="flex flex-col space-y-8 font-bold text-3xl md:text-5xl mt-24 justify-center items-center"
          >
            <span> Contact Us</span>
            <p class="text-xs md:text-base text-center md:max-w-2xl p-4">
              Ready to connect with us? Our contact page provides all the
              necessary information to reach out to our dedicated team. Whether
              you have questions, require support, or wish to explore
              partnership opportunities, we are just a click or call away. Let
              us assist you in every step of your journey
            </p>
          </h1>
          <!-- <p class="text-center text-lg md:text-xl">
          See how you can get your Smart products.
        </p> -->
        </div>
      </div>

      <!-- dedd -->

      <!-- MK-sound -->
      <div
        class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
      >
        <div class="w-full md:w-1/2 text-left">
          <li class="text-xs md:text-base">
            At Lifestyle Technologies Limited, we believe that technology can
            enhance and elevate your everyday life. Join us on this immersive
            tour as we showcase the seamless fusion of innovation, design, and
            functionality that defines our brand.
          </li>
          <li class="text-xs md:text-base">
            Now, fasten your seatbelt and embark on an extraordinary digital
            adventure with Lifestyle Technologies Limited. Begin your tour today
            and unlock a world of limitless possibilities.
          </li>
          <li class="text-xs md:text-base">
            Welcome to Lifestyle Technologies Limited – Where Technology Meets
            Lifestyle.
          </li>
        </div>
        <div class="w-full md:w-1/2 mb-4 flex justify-center">
          <img
            src="../assets/images/smart-20.jpeg"
            class="rounded-lg w-full h-44 md:h-full object-cover"
            alt=""
          />
        </div>
      </div>

      <!-- another -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Products",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    return {};
  },
};
</script>
