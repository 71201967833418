<template>
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-10 h-10"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33875 6.92765H6.47054C6.50286 3.34909 9.41322 0.460449 12.9999 0.460449C16.5866 0.460449 19.4941 3.34918 19.5293 6.92765H20.6611C23.267 6.92765 25.4012 9.05926 25.4012 11.6678C25.4012 14.2737 23.2696 16.4079 20.6611 16.4079H18.2412C17.8721 13.8452 15.665 11.8727 13.0001 11.8727C10.3349 11.8727 8.12787 13.8425 7.75895 16.4079H5.33911C2.73056 16.4079 0.598999 14.2763 0.598999 11.6678C0.59882 9.06205 2.73312 6.92765 5.33911 6.92765H5.33875ZM13 21.665C15.4845 21.665 17.4975 19.652 17.4975 17.1675C17.4975 14.683 15.4845 12.67 13 12.67C10.5182 12.67 8.5025 14.683 8.5025 17.1675C8.5025 19.6522 10.5155 21.665 13 21.665ZM13.3988 19.5416V15.7636L14.4362 16.8011C14.5925 16.9573 14.8459 16.9573 15.0021 16.8011C15.1584 16.6448 15.1584 16.3914 15.0021 16.2352L13.2829 14.5159C13.2802 14.5132 13.2775 14.5105 13.2748 14.5105L13.2641 14.4998C13.2372 14.4755 13.2075 14.4568 13.1752 14.4405L13.1672 14.4379H13.1645L13.1591 14.4352H13.1564L13.1484 14.4325H13.1457L13.1404 14.4298H13.1377L13.127 14.4245H13.1243L13.1189 14.4218H13.1163L13.1109 14.4191H13.1082L13.1002 14.4164H13.0975L13.0734 14.4084H13.0306C13.0116 14.4057 12.9902 14.4057 12.9713 14.4084H12.9284L12.9043 14.4164H12.9016L12.8936 14.4191H12.8909L12.8829 14.4218H12.8802L12.8748 14.4245H12.8722L12.8668 14.4272L12.8652 14.4216H12.8625L12.8545 14.4243H12.8518L12.8438 14.427H12.8411L12.8357 14.4297H12.833L12.825 14.4323C12.7927 14.4486 12.763 14.4673 12.7361 14.4916L12.7307 14.497C12.728 14.4997 12.7254 14.4997 12.7254 14.5023L12.7173 14.5077L10.9981 16.227C10.8418 16.3832 10.8418 16.6366 10.9981 16.7929C11.1543 16.9491 11.4077 16.9491 11.564 16.7929L12.6014 15.7554V19.5307C12.6014 19.7516 12.7793 19.9295 13.0002 19.9295C13.2209 19.9404 13.3988 19.7627 13.3988 19.5416L13.3988 19.5416Z"
      fill="black"
    />
  </svg>
</template>
Upload Image (Add up to 5 hospital facility images)