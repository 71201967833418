<template>
  <div>
    <div class="bg-black">
      <Navbar />
      <div class="aboutus-bg">
        <div class="container mx-auto">
          <div
            class="space-y-2 md:space-y-4 max-w-2xl pt-10 text-xs md:text-base"
          >
            <p class="text-5xl md:text-8xl text-white">About Us ...</p>

            <p>
              Lifestyle Technologies is a quickly evolving custom AV and
              technology integration company.
            </p>
            <p>
              Formed in 2013, we have built our clientele and credibility by
              focusing on one key goal: delivering on our clients’ requests with
              attention to detail. No matter how big or small the job is, client
              satisfaction is the most important thing to us.
            </p>
            <p>
              Our expertise encompasses everything from audio visual systems,
              lighting design and automation, data and IT networking, graphic
              design, security and surveillance, through to acoustic treatments
              and soundproofing construction with core focus on the residential
              and hospitality industries. Each project is custom-made; all our
              work is customized to the needs and desires of the individual
              client.
            </p>
            <p>
              All projects are treated with the utmost care and consideration.
              They’re managed step by step by our project and onsite managers.
              From initial concept drawings drafted by our in-house designers,
              to complex requirements carefully planned by our systems
              professionals through to custom installation and automation; our
              in-house team at Lifestyle Technologies Limited will go out of our
              way to provide for our clients.
            </p>

            <p class="">
              Our cutting-edge systems are backed by ongoing support from our IT
              and AV team. Custom support plans are available for both
              commercial and residential clients.
            </p>
          </div>
          <div class="mt-10">
            <router-link
              to="/contact-us"
              class="custom_btn_small_device md:custom_btn"
              >Enquire Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- <div
        class="container mx-auto flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-12"
      >
        <div class="w-full h-96 md:w-1/2 space-y-4 overflow-y-auto">
          <div class="space-y-2 md:max-w-sm">
            <p>WHAT WE DO</p>
          </div>
          <div class="space-y-2 md:max-w-sm">
            <p>
              Lifestyle Technologies is a quickly evolving custom AV and
              technology integration company.
            </p>
          </div>
          <div class="space-y-2 md:max-w-sm">
            <p>
              Formed in 2013, we have built our clientele and credibility by
              focusing on one key goal: delivering on our clients’ requests with
              attention to detail. No matter how big or small the job is, client
              satisfaction is the most important thing to us.
            </p>
          </div>
          <div class="space-y-2 md:max-w-sm">
            <p>
              Our expertise encompasses everything from audio visual systems,
              lighting design and automation, data and IT networking, graphic
              design, security and surveillance, through to acoustic treatments
              and soundproofing construction with core focus on the residential
              and hospitality industries. Each project is custom-made; all our
              work is customized to the needs and desires of the individual
              client.
            </p>
          </div>
          <div class="space-y-2 md:max-w-sm">
            <p>
              All projects are treated with the utmost care and consideration.
              They’re managed step by step by our project and onsite managers.
              From initial concept drawings drafted by our in-house designers,
              to complex requirements carefully planned by our systems
              professionals through to custom installation and automation; our
              in-house team at Lifestyle Technologies Limited will go out of our
              way to provide for our clients.
            </p>
          </div>
          <div class="space-y-2 md:max-w-sm">
            <p>
              Our cutting-edge systems are backed by ongoing support from our IT
              and AV team. Custom support plans are available for both
              commercial and residential clients.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <img
            src="../assets/images/images/Control4-system-1.jpeg"
            class="rounded-lg border border-KellyGreen w-full rounded-xl"
            alt=""
          />
        </div>
      </div> -->

      <div class="container mx-auto space-y-10 my-16">
        <!-- Salto -->
        <div
          class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              STEP BY STEP
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              Our process is patient and attentive, from first consultation to
              installation and aftercare. We aim to meet your needs and exceed
              your expectations at every stage
            </p>
          </div>
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/smart-22.jpeg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-full object-cover"
              alt=""
            />
          </div>
        </div>
        <!--  Automated RTI -->
        <div
          class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/consultation.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-96 object-cover"
              alt=""
            />
          </div>
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              01. Client Consultation
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              We meet to understand your requirements and how you would like to
              use a particular space. Is the main goal to reduce energy bills on
              site? Or perhaps you want the freedom to play different music from
              room to room. A visit to our Lekki Experience Center will spark
              your imagination. When it comes to new builds, the sooner we are
              involved the better. We can then work more closely with the
              architect or interior designer to fully realize your vision.
            </p>
          </div>
        </div>

        <!-- Salto -->
        <div
          class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              02. Design
            </h2>
            <p
              class="text-center md:text-left md:max-w-md text-xs md:text-base leading-5"
            >
              We make a detailed proposal with hardware and software
              recommendations. Our experienced engineers will draw up a set of
              design and cable schematics to make sure all third-party systems
              are integrated in one system. That way you can control, manage and
              monitor everything from one platform. They can also design a
              custom interface for each installation. Our systems are designed
              to be elegant and unobtrusive, while also optimizing energy
              efficiency.
            </p>
          </div>
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/design.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-96 object-cover"
              alt=""
            />
          </div>
        </div>

        <!-- Monitor-Audio -->
        <div
          class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/projectmanagement.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-full object-cover"
              alt=""
            />
          </div>
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              03. Project Management
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              We meet to understand your requirements and how you would like to
              use a particular space. Is the main goal to reduce energy bills on
              site? Or perhaps you want the freedom to play different music from
              room to room. A visit to our London demo apartment will spark your
              imagination. When it comes to new builds, the sooner we are
              involved the better. We can then work more closely with the
              architect or interior designer to fully realize your vision.
            </p>
          </div>
        </div>

        <!-- MK-sound -->
        <div
          class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              04. Programming & Integration
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              The integrity of an automation project often comes down to the
              quality of the programming. This is one of our core strengths. We
              test rigorously so your systems will be beyond reliable, while any
              connected devices (new or old) will seamlessly communicate with
              one another. The result is intuitive and effortless control of
              your property.
            </p>
          </div>
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/projectintegration.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-96 object-cover"
              alt=""
            />
          </div>
        </div>

        <!-- another -->
        <div
          class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/installation.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-full object-cover"
              alt=""
            />
          </div>
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              05. Installing & Commissioning
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              When completing each fitting, our engineers pay strict attention
              to the finish as well as the functionality. They will make sure
              special features such as in-wall and in-ceiling components are
              carefully matched to the environment. During commissioning they
              test the equipment and fine-tune the system, adhering to a strict
              safety checklist.
            </p>
          </div>
        </div>

        <!-- MK-sound -->
        <div
          class="flex flex-col-reverse md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              06. Handover
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              Once the project is completed, we will prepare your documentation
              and run through everything with you on site. This will allow you
              to adjust and monitor performance as often as you like. We are
              committed to helping you get the most out of your system.
            </p>
          </div>
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/handover.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-96 object-cover"
              alt=""
            />
          </div>
        </div>

        <!-- another -->
        <div
          class="flex flex-col md:flex-row justify-center items-center md:space-x-16"
        >
          <div class="w-full md:w-1/2 mb-4 flex justify-center">
            <img
              src="../assets/images/images/maintain.jpg"
              class="rounded-lg border border-KellyGreen w-full h-44 md:h-full object-cover"
              alt=""
            />
          </div>
          <div class="w-full md:w-1/2 text-left">
            <h2
              class="text-center md:text-left text-xl md:text-2xl mb-4 md:mb-6 md:max-w-sm"
            >
              07. Client care & Maintenance
            </h2>
            <p
              class="text-center md:text-left max-w-md text-xs md:text-base leading-5"
            >
              Lifestyle Technologies is always working to minimize disruption
              and downtime for our clients. That’s why we offer a comprehensive
              warranty with 24/7/365 technical support. We will continuously
              monitor your system for potential issues, make any necessary
              updates, check for faults and fix them either remotely or on site.
              Our engineers will also make regular maintenance visits to help
              maximize energy efficiency, system uptime and occupant comfort. We
              will be there before you need us.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "AboutUsView",
  components: {
    Navbar,
    Footer,
  },
};
</script>
