<template>
  <div>
    <Navbar />
    <div class="mb-10">
      <VideoBackground
        src="/videos_1.mp4"
        poster="poster.jpg"
        :sources="[
          { src: '900>.mp4', res: 900, autoplay: true },
          {
            src: '640.mp4',
            res: 638,
            autoplay: true,
          },
        ]"
        class="max-h-[50%] h-[40vh] md:h-[85vh]"
        overlay="linear-gradient(95deg,#2a4ae430,#fb949e6b)"
      >
        <div
          class="flex flex-col text-center items-center justify-center space-y-10 h-[40vh] md:h-[85vh]"
        >
          <p
            class="font-poppins-semibold font-black text-xl md:text-8xl text-white transition-all duration-500 ease-in-out"
          >
            Consulting Assistance
          </p>
          <div class="flex justify-center">
            <router-link
              to="/contact-us"
              class="custom_btn_small_device md:custom_btn"
            >
              Enquire Now
            </router-link>
          </div>
        </div>
      </VideoBackground>
    </div>

    <div class="space-y-4 md:space-y-20">
      <div v-if="loadingData">
        <loader
          object="#ff9633"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          name="circular"
        ></loader>
      </div>
      <router-link
        v-else
        :to="{ name: 'product-detail', params: { id: product._id } }"
        :key="product._id"
        v-for="product in products"
      >
        <div class="container mx-auto md:w-8/12 relative my-4 md:my-20">
          <img
            :src="product.image"
            alt=""
            class="opacity-40 w-full h-96 object-cover rounded-2xl hover:border-2 hover:border-KellyGreen"
          />
          <div class="absolute top-1/4 px-2 md:px-20 rounded-lg space-y-3">
            <div class="flex flex-col space-y-2 font-bold text-3xl md:text-5xl">
              <span> {{ product.name }}</span>
            </div>
            <p class="max-w-md">
              {{ ReduceText(product.details) }}
            </p>
          </div>
        </div>
      </router-link>
    </div>
    <!-- <div class="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-6">
      <router-link
        :to="{ name: 'product-detail', params: { id: product._id } }"
        :key="product._id"
        v-for="product in products"
      >
        <div class="h-min overflow-hidden rounded-md">
          <img
            class="w-full h-44 object-cover hover:scale-125 transition-all duration-1000 cursor-pointer"
            :src="product.image"
            alt=""
          />
        </div>
        <div
          class="flex flex-row items-center justify-between p-4 shadow-2xl rounded-b-lg"
        >
          <h6>{{ product.name }}</h6>

          <div>&#8358; {{ product.price }}</div>
        </div>
      </router-link>
    </div> -->
    <Footer />
  </div>
  <div>
    <transition name="fade" appear>
      <div
        class="modal-overlay"
        v-if="showModal"
        @click="showModal = false"
      ></div>
    </transition>
    <transition name="pop">
      <div class="modal" role="dialog" v-if="showModal">
        <div class="modal_div py-32">
          <div class="flex flex-row items-center justify-between mb-10">
            <p class="text-2xl">Enquiry Now</p>
            <button
              @click="showModal = false"
              class="text-[#FF0000] p-1 rounded-full"
            >
              X
            </button>
          </div>
          <div class="flex flex-col space-y-8 mb-6">
            <input
              class="w-full bg-[#fff] p-3 rounded-md border border-[#9A9A9A] focus:outline-none focus:border-KellyGreen focus:ring-0"
              type="text"
              name="Name"
              placeholder="Name"
            />
            <input
              class="w-full bg-[#fff] p-3 rounded-md border border-[#9A9A9A] focus:outline-none focus:border-KellyGreen focus:ring-0"
              type="email"
              name="email"
              placeholder="E-mail"
            />
            <textarea
              name=""
              id=""
              cols="25"
              rows="4"
              placeholder="Message"
              class="w-full bg-[#fff] p-3 rounded-md border border-[#9A9A9A] focus:outline-none focus:border-KellyGreen focus:ring-0"
            ></textarea>
          </div>
          <div class="flex flex-row justify-center space-x-3">
            <button class="custom_btn_small_device md:custom_btn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/runtime-core";

import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import EnquiryNow from "@/components/EnquiryNow.vue";
import VideoBackground from "vue-responsive-video-background-player";
import myMixin from "../utils/myMixin.js";
export default defineComponent({
  name: "HomeTechnonolyView",
  mixins: [myMixin],
  components: {
    Navbar,
    Footer,
    EnquiryNow,
    VideoBackground,
  },
  setup() {
    const showModal = ref(false);
    const activeMain = () => {
      return $route.path === "/";
    };
    return {
      activeMain,
      showModal,
    };
  },

  data() {
    return {
      products: [],
      id: null,
      loadingData: false,
    };
  },

  methods: {
    async queryProduct() {
      this.loadingData = true;
      await this.$store.dispatch("query", {
        endpoint: "listProduct",
        storeKey: "productList",
      });
      this.products = this.$store.state.data.productList.filter((product) => {
        return product.category === "Consulting";
      });
      console.log(this.products, "tttttt");
      console.log(this.$store.state.data.productList, "uuuuu");
      this.loadingData = false;
    },
  },
  created() {
    this.queryProduct();
  },
});
</script>

<style scoped>
.modal {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  padding: 2rem;
  border-radius: 1rem;
  background: #000;
  z-index: 999;
  transform: none;
  display: flex;
  justify-content: center;
}

.modal_div {
  width: 600px;
}
.modal h1 {
  margin: 0 0 1rem;
}

.modal-overlay {
  content: "";
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #2c3e50;
  background: #2c3e50;
  opacity: 0.6;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
